import { FunctionComponent, CSSProperties } from "react";

interface Props {
  size: number;
  style?: CSSProperties;
}
export const Logo: FunctionComponent<Props> = props => (
  <svg style={{ verticalAlign: 'middle', ...props.style }} width={props.size} height={props.size} viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="500" y="1.14441e-05" width="500" height="500" rx="250" transform="rotate(90 500 1.14441e-05)" fill="#bb3300"/>
<path d="M138.69 121.92C221.633 57.5219 505.081 245.485 337.074 375.934C169.041 506.427 55.7854 186.293 138.69 121.92Z" fill="white"/>
<path d="M196.801 189.345C236.696 158.508 372.735 248.13 291.925 310.594C211.103 373.079 156.925 220.169 196.801 189.345Z" fill="#bb3300"/>
  </svg>
);
